@import './../../style/Colors.scss';

.box-container {
  width: 380px;
  max-width: 380px;
  position: fixed;
  margin-bottom: 50px;
  margin-right: 50px;
  bottom: 0;
  right: 0;
  padding: 20px;
  color: $primary-text-color;
  background-color: $primary-backgroundcolor;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;

  .box-item {
    .box-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .box-text {
      padding-top: 10px;
      font-size: 16px;
      line-height: 1.4;
    }
  }
}

@media (max-width: 900px) {
  .box-container {
    width: auto;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: 20px;

    .box-item {
      .box-title {
        font-size: 16px;
        margin-bottom: 5px;
      }

      .box-text {
        padding-top: 5px;
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 480px) {
  .box-container {
    min-height: 80px;
    width: 80%;
    position: fixed;
    margin-bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    margin-right: 0px;
    margin-left: 0px;
  }
}