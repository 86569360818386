@import './../../style/Global.scss';

@mixin box-shadow($top, $left, $blur, $size, $color) {
    -webkit-box-shadow: $top $left $blur $size $color;
    -moz-box-shadow: $top $left $blur $size $color;
    box-shadow: $top $left $blur $size $color;
}

.logo-container {
    border-radius: 12px;
    text-align: center;
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 300;
    font-weight: bold;
    padding: 7px;
    color: $secondary-text-color !important;
    font-size: 18px;

    img {
        width: 70px;
    }

}

.nav {
    background-color: $blue-primary !important;
    width: 100%;
    align-items: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 18px;

    .nav-item {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        width: 33%;

        &.nav-item-sm {
            font-size: 18px;
            font-weight: 700;
        }

        &:first-child {
            padding-left: 35px;
            justify-content: flex-start;
        }

        &:last-child {
            padding-right: 35px;
            justify-content: flex-end;
        }

        #nav-logo {
            margin-right: 5px;

            img {
                width: 40px;
            }
        }
    }

    .drawer-toggle-div {
        position: absolute;
        top: 16px;
        left: 16px;
        width: 50px;
        height: 50px;
        background-color: $blue-primary;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 3px 6px $shadow-color;
        z-index: 300;

        .drawer-toggle-icon {
            width: 20px;
            height: 20px;
            color: $white;
        }
    }
}

.nav-logo-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 22px;
    color: $secondary-text-color !important;
    font-weight: 700;

    #nav-logo {
        img {
            max-height: 50px;
            margin-left: 20px;
        }
    }
}

.drawer-menu {
    line-height: 1.8;
    padding-top: 7px;

    .drawer-menu-header {
        font-weight: bold;
        padding-left: 20px;
    }

    .drawer-list {
        .drawer-list-item {
            display: flex;
            justify-content: left;
            align-items: center;
            outline: none;
            text-decoration: none;
            color: $secondary-text-color !important;
            border-top: 1px solid lighten($color: $blue-primary, $amount: 15);

            &.active {
                border-bottom: 2px solid $blue-primary;
                font-weight: bold;
            }

            a {
                &.drawer-link {
                    margin-right: 30px;
                    outline: none;
                    text-decoration: none;
                    font-weight: 500;
                    color: $secondary-text-color !important;

                    &:hover {
                        background-color: 2px solid $primary-backgroundcolor;
                        font-weight: bold;
                    }

                    .drawer-item-icon {
                        margin-right: 10px
                    }

                }

            }

        }
    }
}

@media (min-width: 900px) {
    .nav {
        height: $nav-height;

        .drawer-toggle-div {
            position: unset;
            z-index: 300;
            width: 40px;
            background-color: $blue-primary;
            display: flex;
            justify-content: center;
            align-items: center;

            .drawer-toggle-icon {
                width: 30px;
                height: 30px;
                color: $white;
                cursor: pointer;
            }
        }
    }

    .menu-container {
        padding: 0 20px 10px 20px;
        border-radius: none !important;
        width: 100%;
        z-index: 600;
        height: 100px;

        .external-links {
            font-size: 14px;

            a {
                color: lighten($primary-text-color, 25%) !important;

                &:hover {
                    color: $primary-text-color !important;
                }
            }
        }

        .navigation-tabs {
            float: right;
            background-color: $white !important;
            display: flex;

            & .nav-link {
                margin-right: 10px;
                color: $primary-text-color ;
                margin: 0px 16px;
                padding: 8px 0px;
            }

            .active {
                border-bottom: 2px solid $blue-primary;
                font-weight: bold;
                margin: 0px 16px;
                padding: 8px 0px;
            }
        }

        .nav-logo-container {
            margin-top: -17px;

            #nav-logo {
                img {
                    max-height: 60px;
                    margin-left: 20px;
                }
            }
        }
    }
}