$blue-primary: #233651;
$blue-1: #035e8e;
$blue-2: #0091d4;
$blue-3: #65bce8;
$blue-4: #92d0f0;
$blue-5: #acc6d6;
$blue-6: #d5e2e7;

$black: #000000;
$dark-grey: #404040;
$white: #ffffff;

$background-color: #f8f8f8;
$shadow-color: #4D4D4D29;

$primary-text-color: $white;
$primary-backgroundcolor: $blue-primary;

$secondary-backgroundcolor: $white;
$secondary-text-color: $blue-primary;