@import "../../style/Global.scss";

@media (min-width: 280px) {
    #map-container {
        margin-top: 0px;
        width: 100%;
        height: 100vh;
        font-family: $font;
    }

    .loading-container {
        background: $blue-6;
        /* Fallback for older browsers without RGBA-support */
        background: rgba(253, 253, 253, 0.3);
        position: absolute;
        width: 100%;
        height: 100vh;
        z-index: 600;
    }
}

@media (min-width: 900px) {
    #map-container {
        margin-top: 0px;
        width: 100%;
        height: $map-height;
    }

    .loading-container {
        height: $map-height;
    }
}